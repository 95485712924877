import React, { useState } from 'react';
import ReactPlayer from 'react-player';
import './style.css';


const VideoDialog = ({ handlestop }) => {
    const [status, setStatus] = useState(false);

    const handleClickOutside = (e) => {
        if (e.target.classList.contains('overlay')) {
            setStatus(false);
            handlestop();
        }
    }

    return (
        <div onClick={handleClickOutside} className='relative'>
            <div className="fixed overlay w-screen h-screen bg-black bg-opacity-50 z-10"></div>
            {/* overlay */}
            <div className="absolute z-50 sm:p-3 md:p-8 bg-black video-container">
                <div className="dialog__content">
                {/* <iframe width="660" className="z-50" height="415" src="https://www.youtube.com/embed/RzIjyqdIj5c" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen ref={videoRef} ></iframe> */}
                <ReactPlayer className="sm:w-full md:w-auto" url='https://www.youtube.com/watch?v=RzIjyqdIj5c' playing={status} />
                </div>
            </div>
        </div>
    );
}

export default VideoDialog;