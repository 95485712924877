// sample page

// import React from 'react';
// import { Link } from 'react-router-dom';
import challenge from '../../assets/img/challenges.png';
import hero3 from '../../assets/img/hero3.png';
import plate1 from '../../assets/img/plate1.png';
import plate2 from '../../assets/img/plate2.png';
import plate3 from '../../assets/img/plate3.png';
import plate4 from '../../assets/img/plate4.png';
import plate5 from '../../assets/img/plate5.png';
import plate6 from '../../assets/img/plate6.png';
import plates from '../../assets/img/plates.png';
import solution1 from '../../assets/img/solution1.png';
import solution2 from '../../assets/img/solution2.png';
import tools from '../../assets/img/tools.png';
import upresslogo from '../../assets/img/upress-logo.png';
import upress from '../../assets/img/upress.png';
import Header from '../../components/Header/Index';
import VideoDialog from '../../components/VideoDialog/Index';

import './Home.css';

// const useStyle = () => {
//     return {
//         heroContent: {
//             height: '100vh',
//             backgroundImage: `url(${bg})`,
//             backgroundSize: 'cover',
//             backgroundPosition: 'center',
//         }
//     }
// }

const Home = () => {
    // open video dialog on click
    const openVideoDialog = () => {
        // toggle classlist
        const videoDialog = document.querySelector('.video-modal');
        videoDialog.classList.toggle('hidden');

        // remove overlay
        // const overlay = document.querySelector('.overlay');
        // overlay.classList.remove('hidden');
    }

    // close video dialog on overlay click
    const closeVideoDialog = () => {
        const videoDialog = document.querySelector('.video-modal');
        videoDialog.classList.add('hidden');
    }
    return (
        <div className='page relative'>
            {/* <div className='overlay hidden absolute top-0 left-0 w-full h-full z-40 bg-black opacity-20' onClick={closeVideoDialog}> */}
            {/* </div> */}
            <Header className='sticky'></Header>
            <div className=''>
                {/* overlay */}
                <div className='video-modal hidden z-50 fixed'>
                    <VideoDialog className='video-dialog' handlestop={closeVideoDialog}></VideoDialog>
                </div>
                <div className="hero-section bg-hero relative flex">
                    <div className='m-auto container'>
                        <h1 className='hero-title text-lg w-96'>
                            Providing innovative
                            and secure identification
                            solutions for Ghana
                        </h1>
                        <div className='flex my-5'>
                            {/* play icon */}
                            <div className='play-btn cursor-pointer rounded-lg h-12 w-12 bg-primary flex px-2' onClick={openVideoDialog}>
                                <svg className='w-10 h-10 m-auto' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="white">
                                    <path fillRule="evenodd" d="M4 3a1 1 0 00-1 1v12a1 1 0 001.555.832l12-6a1 1 0 000-1.664l-12-6A1 1 0 004 3z" clipRule="evenodd" />
                                </svg>
                            </div>
                            <div className='ml-4 w-56'>
                                <h2 className='text-sm font-light'>Find out how our partner was founded and grow. Watch video</h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='min-h-144 py-32 bg-sectionBg bg-cover bg-right-top mx-auto' id='about'>
                    <div className='flex flex-col lg:flex-row container mx-auto'>
                        <div className='flex-1'>
                            <div className='cards grid grid-cols-2 lg:grid-cols-3 gap-3 px-10'>
                                <div className='card bg-white p-5 shadow-md rounded-md'>
                                    <div className='card-icon mx-auto w-full flex place-content-center'>
                                        <img src={plate1} alt="Security License Plate 1" />
                                    </div>
                                    <div className='card-content'>
                                        <p className='text-sm'>Security License Plates</p>
                                    </div>
                                </div>
                                <div className='card bg-white p-5 shadow-md rounded-md'>
                                    <div className='card-icon mx-auto w-full flex place-content-center'>
                                        <img src={plate2} alt="Security License Plate 1" />
                                    </div>
                                    <div className='card-content'>
                                        <p className='text-sm'>Machines & Engineering</p>
                                    </div>
                                </div>
                                <div className='card bg-white p-5 shadow-md rounded-md'>
                                    <div className='card-icon mx-auto w-full flex place-content-center'>
                                        <img src={plate3} alt="Security License Plate 1" />
                                    </div>
                                    <div className='card-content'>
                                        <p className='text-sm'>Service & Maintenance</p>
                                    </div>
                                </div>
                                <div className='card bg-white p-5 shadow-md rounded-md'>
                                    <div className='card-icon mx-auto w-full flex place-content-center'>
                                        <img src={plate4} alt="Security License Plate 1" />
                                    </div>
                                    <div className='card-content'>
                                        <p className='text-sm'>RFID - Solution</p>
                                    </div>
                                </div>
                                <div className='card bg-white p-5 shadow-md rounded-md'>
                                    <div className='card-icon mx-auto w-full flex place-content-center'>
                                        <img src={plate5} alt="Security License Plate 1" />
                                    </div>
                                    <div className='card-content'>
                                        <p className='text-sm'>Modular Systems</p>
                                    </div>
                                </div>
                                <div className='card bg-white p-5 shadow-md rounded-md'>
                                    <div className='card-icon mx-auto w-full flex place-content-center'>
                                        <img src={plate6} alt="Security License Plate 1" />
                                    </div>
                                    <div className='card-content'>
                                        <p className='text-sm'>Additional Products</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='flex-1 my-auto ml-20'>
                            <div className='content'>
                                <h2 className='text-lg mb-2 font-normal'>Our Partnered <br />Portfolio</h2>
                                <p className='text-sm mb-8 font-light'>
                                    Whatever is needed to produce security license plates, <br />we can provide it.
                                </p>
                                <div classNamme='btn'>
                                    <button className='btn-primary bg-black text-white p-3 rounded-md w-44'>Read more...</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='w-full'>
                    <div className='mx-auto container w-full'>
                        <div className='section min-h-144 py-20 bg-white'>
                            <h2 className='text-lg mb-2 text-center font-normal'>Actual <span className='text text-danger'>global</span> Challenges Worldwide</h2>
                        </div>
                        {/* image */}
                        <div className='img-container w-full mx-auto flex place-content-center items-center'>
                            <img src={challenge} className='text-center content-center mx-auto' alt="Security License Plate 1" />
                        </div>
                        {/* spacer line */}
                        <div className='w-full h-0.5 my-20 bg-grey'></div>
                    </div>
                </div>
                <div className='min-h-144 py-20 bg-wave bg-cover bg-no-repeat bg-center bg-opacity-50'>
                    <div className='container block lg:flex mx-auto mb-20'>
                        <div className='flex-1 lg:pr-10'>
                            <h2 className='text-base mb-2 font-normal text-danger'>Our Solution</h2>
                            <h3 className='title text-lg font-normal mb-10'>
                                <span className='text-danger'>Third</span> License <br />Plate.
                                <br />Windshield label
                            </h3>
                            <p className='text-sm mb-8 font-light w-4/5'>
                                The windshield label or "third license plate" is an additional means of identification that complements the traditional license plate. In the form of the ulabel with RFID unit, it also offers completely new possibilities in electronic vehicle identification.
                            </p>
                            <div classNamme='btn mt-5'>
                                <p className='font-light text-danger cursor-pointer'>Read More...</p>
                            </div>
                        </div>
                        <div className='flex-1'>
                            <div className='img-container w-full mx-auto flex place-content-center'>
                                <img src={solution1} alt='' />
                            </div>
                        </div>
                    </div>
                    <div className='container lg:flex flex-row-reverse mx-auto'>
                        <div className='flex-1 my-auto'>
                            <h3 className='title text-lg font-normal mb-10'>
                                <span className='text-danger'>DigSig</span> Digital <br />signature.
                            </h3>
                            <p className='text-sm mb-8 font-light'>
                                The windshield label or "third license plate" is an additional means of identification that complements the traditional license plate. In the form of the ulabel with RFID unit, it also offers completely new possibilities in electronic vehicle identification.
                            </p>
                        </div>
                        <div className='flex-1'>
                            <div className='img-container w-full mx-auto flex place-content-start'>
                                <img className='w-4/5' src={solution2} alt='' />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='bg-hero-2 bg-contain py-20'>
                    <div className='container lg:flex mx-auto text-white'>
                        <div className='flex-1 my-auto'>
                            <h2 className='text-lg mb-2 font-normal'>Benefits of our Solution</h2>
                        </div>
                        <div className='flex-1 my-auto'>
                            <div className='content'>
                                <ul className='list-disc list-inside'>
                                    <li className='text-sm mb-2 font-light'>Road police can control road traffic participants though the realible and simple electronic tool</li>
                                    <li className='text-sm mb-2 font-light'>Higher security due to less unregistered and non-insured vehicles</li>
                                    <li className='text-sm mb-2 font-light'>Fake plates and documents can be easily detected</li>
                                    <li className='text-sm mb-2 font-light'>Less possibilities for corruption due to a computerized electronic tool etc...</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <img src={hero3} alt="Security License Plate 1" className='mx-auto' />
                <div className='bg-slate py-20'>
                    <div className='container lg:flex mx-auto text-black'>
                        <div className='flex-1 my-auto'>
                            <h2 className='text-lg mb-2 font-normal'>How UTSCH licence plates represent a secure document.</h2>
                            <p className='text-sm mb-8 font-light'>
                                Licence plates are particularly exposed by the way they are used. Attached to the front and rear of a vehicle, they are easily accessible to everyone in daily practice and as such, prone to manipulation and counterfeiting.
                            </p>
                            <p className='text-sm mb-8 font-light'>
                                In order to make this more difficult, UTSCH has been constantly adding new security features since the 1990s. Some of the features are invisible to the naked eye and only become recognisable through the use of special tools.
                            </p>
                        </div>
                        <div className='flex-1 my-auto'>
                            <div className='img-container w-4/5 mx-auto flex place-content-center'>
                                <img src={plates} alt='' />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='bg-hero-4 bg-contain py-20' id='products'>
                    <div className='container lg:flex mx-auto text-white'>
                        <div className='flex-1 my-auto'>
                            <img src={upress} alt="Security License Plate 1" className='w-4/5' />
                        </div>
                        <div className='flex-1 text-black'>
                            <img src={upresslogo} alt="Security License Plate 1" className='w-80' />
                            <h3 className='text-md my-8 font-normal'>
                                NEW: the smart embossing press offering a variety of options
                            </h3>
                            <p className='text-sm mb-8 font-light'>
                                The <span className='font-normal'><span className='text-danger'>u</span>press<span className='text-danger'>light</span></span> perfectly combines proven UTSCH embossing technology with the extensive possibilities of a networked workplace.
                            </p>
                            <p className='text-sm mb-8 font-light'>
                                "Smart" components ensure the optimised and centralised processing of orders, with the simultaneous collection of economic indicators and increased operator safety.
                            </p>
                            <p className='text-sm mb-8 font-light'>
                                As the mechanical embossing technology used in the <span className='font-normal'><span className='text-danger'>u</span>press<span className='text-danger'>light</span></span> is based on UTSCH's proven and popular 500 kN embossing press, an upgrade of existing embossing presses of this type up to the level of the <span className='font-normal'><span className='text-danger'>u</span>press<span className='text-danger'>light</span></span> is possible in some cases.
                            </p>
                        </div>
                    </div>
                </div>
                <div className='bg-hero-4 bg-contain py-20'>
                    <div className='container lg:flex lg:flex-row-reverse mx-auto text-white'>
                        <div className='flex-1 my-auto'>
                            <img src={tools} alt="Security License Plate 1" className='w-4/5' />
                        </div>
                        <div className='flex-1 text-black my-auto'>
                            <h3 className='text-md my-8 font-normal'>
                                Manual machines
                            </h3>
                            <p className='text-sm mb-8 font-light'>
                                UTSCH Ghana supplies a wide range of manually operated machines with very different performance specifications. Perfectly adapted to the needs of embossing firms of any size.
                            </p>
                            <p className='text-sm mb-8 font-light'>
                                UTSCH machines are easy to operate, require minimal maintenance and deliver reliable, precise results.
                            </p>
                        </div>
                    </div>
                </div>
                <div className=' bg-dark py-20' id='contact'>
                    <div className='container lg:flex mx-auto text-white'>
                        <div className='hidden lg:block flex-1 my-auto'>
                            <h3 className='text-md font-light'>Please contact us for more <br/>information.</h3>
                        </div>
                        <div className='flex-1 my-auto'>
                            <div className='content'>
                                <h3 className='text-base font-light'>Contact us below to work together to build your <br/> amazing world</h3>
                                {/* button */}
                                <button className='btn py-3 px-5 mt-5 bg-white text-black font-light'>
                                    Contact Us
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Home;