import React from "react";
// import { Routes } from "react-router-dom";
// import routes as switch from react-router-dom
import { Route, Routes  as Switch } from "react-router-dom";
// import RoutesWithLayout from "./components/RoutesWithLayout/RoutesWithLayout";
// import Main from "./layouts/Main/Main";
import Home from "./pages/Home/Home";

const Routes = () => {
  return (
    <Switch>
        <Route path="/" element={<Home />} />
    </Switch>
  );
};

export default Routes;