// import { Link } from 'react-router-dom';
import React from 'react';
import logo from '../../assets/img/logo.svg';
import './style.css';

const Link = ({ href, children }) => {
    return (
      <a href={href}>
        {children}
      </a>
    );
};

const Header = () => {
    return (
        <div className="header bg-white shadow-sm lg:block sm:hidden">
            <div className="header__top py-3">
                <div className="container flex justify-between">
                    <img src={logo} alt="logo"/>
                    <div className="nav-links h-full self-center">
                        <ul className="flex justify-between items-center h-full">
                            <li className="nav-link mx-5">
                                <Link href="#">Home</Link>
                            </li>
                            <li className="nav-link mx-5">
                                <Link href="#about">About</Link>
                            </li>
                            <li className="nav-link mx-5">
                                <Link href="#products">Products</Link>
                            </li>
                            <li className="nav-link mx-5">
                                <Link href="#contact">Contact Us</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Header;